import { Dispatch } from 'react';
import { DefaultRootState } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';

import urlParser from 'bloko/common/urlParser';

import { AddNotification } from 'lux/components/Notifications/Provider/types';
import { LoadingActivityName } from 'lux/components/SavedSearch/types';
import {
    getVacancySavedSearchAction,
    vacancySavedSearchLoadingAction,
    VacancySavedSearchItem,
} from 'lux/models/vacancySavedSearch';
import fetcher from 'lux/modules/fetcher';
import defaultRequestErrorHandler from 'lux/requests/notifications/defaultRequestErrorHandler';

declare global {
    interface FetcherGetApi {
        getSavedSearchesUrl: {
            queryParams: void;
            body: void;
            response: { savedSearch: VacancySavedSearchItem[] };
        };
    }
    interface FetcherPostApi {
        changeSavedSearchesUrl: {
            queryParams: void;
            body: { idList: number[] };
            response: void;
        };
    }
}

export default (
        addNotification: AddNotification,
        path: LoadingActivityName,
        idList?: number[]
    ): ThunkAction<Promise<unknown>, DefaultRootState, unknown, AnyAction> =>
    async (dispatch: Dispatch<unknown>, getState: () => DefaultRootState) => {
        const {
            vacancySavedSearches: { selectedItems },
            router: { location },
        } = getState();
        const parsedUrl = urlParser(`${location.pathname}${location.search}`);
        dispatch(vacancySavedSearchLoadingAction(path));
        const pageParam = (Array.isArray(parsedUrl.params.page) && parsedUrl.params.page[0]) || 0;
        try {
            await fetcher.postFormData(`/vacancysavedsearch/${path}` as 'changeSavedSearchesUrl', {
                idList: idList || selectedItems,
            });
            const vacancySavedSearch = await fetcher.get(
                `/vacancysavedsearch/get_list${location.search}` as 'getSavedSearchesUrl'
            );

            if (vacancySavedSearch.savedSearch.length === 0 && pageParam > 0) {
                window.location.href = location.pathname;
                return;
            }
            dispatch(getVacancySavedSearchAction(vacancySavedSearch));
        } catch (error) {
            defaultRequestErrorHandler(error, addNotification);
        } finally {
            dispatch(vacancySavedSearchLoadingAction(undefined));
        }
    };
