import { Fragment } from 'react';

import { VSpacingContainer, VSpacing } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import Attention, { AttentionKind } from 'lux/components/Attention';
import translation from 'lux/components/translation';
import { useSelector } from 'lux/modules/useSelector';

import ChatBot from 'lux/pages/VacancySavedSearch/components/ChatBot';
import EmptyMessage from 'lux/pages/VacancySavedSearch/components/EmptyMessage';
import VacancySavedSearchItem from 'lux/pages/VacancySavedSearch/components/VacancySavedSearchItem';

const TrlKeys = {
    errorText: 'vacancySavedSearch.error',
};

const VacancySavedSearchContent: TranslatedComponent = ({ trls }) => {
    const { savedSearch, error, paging } = useSelector((state) => state.vacancySavedSearches);
    const isFirstPage = !paging || paging.current === 1;

    if (error) {
        return <Attention kind={AttentionKind.Bad}>{trls[TrlKeys.errorText]}</Attention>;
    }

    if (!savedSearch?.length) {
        return (
            <>
                <EmptyMessage />
                {isFirstPage && (
                    <>
                        <VSpacing default={36} />
                        <ChatBot />
                    </>
                )}
            </>
        );
    }

    return (
        <VSpacingContainer default={36}>
            {savedSearch.map((data, index) => (
                <Fragment key={data.id}>
                    <VacancySavedSearchItem data={data} />
                    {index === 0 && isFirstPage && <ChatBot />}
                </Fragment>
            ))}
        </VSpacingContainer>
    );
};

export default translation(VacancySavedSearchContent);
