import { Select } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import urlParser from 'bloko/common/urlParser';

import { FilterType } from 'lux/components/SavedSearch/types';
import translation from 'lux/components/translation';
import { useSelector } from 'lux/modules/useSelector';

interface FilterTypeProps {
    filterValue: FilterType;
}

const TrlKeys = {
    [FilterType.All]: 'savedSearches.filter.all',
    [FilterType.Subscribed]: 'savedSearches.filter.subscribed',
    [FilterType.Unsubscribed]: 'savedSearches.filter.unsubscribed',
};

const FILTER_TYPES = [FilterType.All, FilterType.Subscribed, FilterType.Unsubscribed];

const FilterTypeSelect: TranslatedComponent<FilterTypeProps> = ({ filterValue, trls }) => {
    const location = useSelector(({ router }) => router.location);

    const handleFilterChange = (value: string) => {
        const parsedUrl = urlParser(`${location.pathname}${location.search}`);
        parsedUrl.params.filter = [value];
        parsedUrl.params.page = [0];
        window.location.href = parsedUrl.href;
    };

    const options = FILTER_TYPES.map((type) => ({ label: trls[TrlKeys[type]], value: type }));

    return (
        <Select
            name="autosearch-filter"
            value={filterValue}
            options={options}
            onChange={handleFilterChange}
            data-qa="autosearch-header__filter"
            widthEqualToActivator={false}
            bottomSheetHeight="content"
        />
    );
};

export default translation(FilterTypeSelect);
