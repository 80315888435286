import React, { ReactNode, FC } from 'react';

import { Title, VSpacing, Checkbox, HSpacing, NumberPages, NumberPagesAction } from '@hh.ru/magritte-ui';
import { Link } from '@hh.ru/redux-spa-middleware';
import urlParser from 'bloko/common/urlParser';

import { useSelector } from 'lux/modules/useSelector';

import FilterTypeSelect from 'lux/components/SavedSearch/Controls/FilterTypeSelect';
import { FilterType, Paging } from 'lux/components/SavedSearch/types';

import styles from './styles.less';

interface SavedSearchProps {
    title?: string;
    message?: ReactNode;
    isAllSelected: boolean;
    handleAllSelected: () => void;
    filterValue: FilterType;
    controls: ReactNode[];
    content: ReactNode;
    paging?: Paging;
}

const SavedSearch: FC<SavedSearchProps> = ({
    title,
    message,
    isAllSelected,
    handleAllSelected,
    filterValue,
    controls,
    content,
    paging,
}) => {
    const location = useSelector((state) => state.router.location);

    return (
        <>
            {title && (
                <>
                    <Title Element="h1" size="large">
                        {title}
                    </Title>
                    <VSpacing default={52} />
                </>
            )}
            {message}
            <div className={styles.savedSearchControlsContainer}>
                <div className={styles.savedSearchControls}>
                    <Checkbox
                        checked={isAllSelected}
                        onChange={handleAllSelected}
                        data-qa="autosearch-header__select-all"
                    />
                    <HSpacing default={24} />
                    <div className={styles.savedSearchButtons}>{controls.map((control) => control)}</div>
                </div>
                <div className={styles.savedSearchSelect}>
                    <FilterTypeSelect filterValue={filterValue} />
                </div>
            </div>
            <VSpacing default={40} />
            {content}
            <VSpacing default={40} />
            {paging && (
                <>
                    <NumberPages
                        {...paging}
                        renderItem={({ page, ...props }) => {
                            if (page) {
                                const urlObject = urlParser(location.pathname + location.search);
                                urlObject.params.page = page - 1;
                                return <NumberPagesAction {...props} Element={Link} to={urlObject.href} />;
                            }
                            return null;
                        }}
                        renderNavigation={({ page, isNext, isPrevious, ...props }) => {
                            if (page) {
                                const urlObject = urlParser(location.pathname + location.search);
                                urlObject.params.page = page - 1;
                                return (
                                    <NumberPagesAction
                                        {...props}
                                        isIcon={isNext || isPrevious}
                                        Element={Link}
                                        to={urlObject.href}
                                    />
                                );
                            }
                            return null;
                        }}
                    />
                    <VSpacing default={40} />
                </>
            )}
        </>
    );
};

export default SavedSearch;
