import { Button, GridColumn, HSpacingContainer, Text, VSpacing } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import AutosearchMessengersModal from 'lux/components/AutosearchMessengers/AutosearchMessengersModal';
import translation from 'lux/components/translation';
import useToggleState from 'lux/hooks/useToggleState';
import { useSelector } from 'lux/modules/useSelector';

const TrlKeys = {
    button: 'autosearch.messengers.chatbot.button',
    text: 'autosearch.messengers.chatbot.savedSearch.text',
};

const ChatBot: TranslatedComponent = ({ trls }) => {
    const staticHost = useSelector(({ config }) => config.staticHost);
    const chatBot = useSelector((store) => store.chatBot);

    const [modalIsVisible, toggleModal] = useToggleState(false);

    if (!chatBot) {
        return null;
    }

    return (
        <>
            <HSpacingContainer default={24}>
                <img src={`${staticHost}/i/hh/autosearch-chatbot.svg`} alt="chatbot" />
                <GridColumn xs={2} s={5}>
                    <Text>{trls[TrlKeys.text]}</Text>
                    <VSpacing default={4} />
                    <Button size="small" mode="secondary" onClick={toggleModal}>
                        {trls[TrlKeys.button]}
                    </Button>
                </GridColumn>
            </HSpacingContainer>
            <AutosearchMessengersModal isVisible={modalIsVisible} handleCloseModal={toggleModal} />
        </>
    );
};

export default translation(ChatBot);
