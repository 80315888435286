import { Link as MagritteLink, Text, VSpacing } from '@hh.ru/magritte-ui';
import { Link } from '@hh.ru/redux-spa-middleware';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { formatToReactComponent } from 'bloko/common/trl';

import translation from 'lux/components/translation';
import { useIsHhru } from 'lux/hooks/useSites';
import routePaths from 'lux/modules/routePaths';

const TrlKeys = {
    noSearches: 'savedsearch.vacancySearch.noSearches',
    subscribe: 'savedsearch.vacancySearch.subscribe',
    detailsLink: 'savedsearch.vacancySearch.subscribe.detailsLink',
    subscribeLink: 'savedsearch.vacancySearch.subscribe.subscribeLink',
};

const EmptyMessage: TranslatedComponent = ({ trls }) => {
    const isHhru = useIsHhru();

    const aboutAutoSearchLink = isHhru ? 'https://feedback.hh.ru/article/details/id/1617' : '/article/14';

    return (
        <>
            <Text>{trls[TrlKeys.noSearches]}</Text>
            <VSpacing default={8} />
            <Text>
                {formatToReactComponent(trls[TrlKeys.subscribe], {
                    '{0}': (
                        <MagritteLink href={aboutAutoSearchLink} target="_blank" inline>
                            {trls[TrlKeys.detailsLink]}
                        </MagritteLink>
                    ),
                    '{1}': (
                        <MagritteLink Element={Link} to={routePaths.subscribe} inline>
                            {trls[TrlKeys.subscribeLink]}
                        </MagritteLink>
                    ),
                })}
            </Text>
        </>
    );
};

export default translation(EmptyMessage);
