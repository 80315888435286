import { FC, PropsWithChildren } from 'react';

import { GridLayout, type GridLayoutProps } from '@hh.ru/magritte-ui';

import RowContent from 'lux/components/RowContent';

interface DefaultLayoutProps extends GridLayoutProps {
    /** Сжатый контент */
    isShrinked?: boolean;
}

type MakeAllKeysRequired<T> = { [key in keyof Omit<Required<T>, 'children'>]: T[key] };
type RequiredGridLayoutProps = MakeAllKeysRequired<GridLayoutProps>;

const DefaultLayout: FC<PropsWithChildren<DefaultLayoutProps>> = ({ children, isShrinked, ...layoutProps }) => {
    /**
     * В PageLayout тип пропсов описан как мерж его собственных пропсов и пропсов компонента переданного в качестве
     * LayoutComponent. Все пропсы LayoutComponent попадают в переменную layoutProps. Но еще туда же попадает проп
     * title, который является собственным пропсом PageLayout. Поэтому его нужно выкинуть из layoutProps прежде чем
     * использовать в LayoutComponent, иначе он попадет в верстку. Сделать это в PageLayout нельзя, т.к. он нужен
     * в одном из лейаутов. Убрать его здесь нельзя т.к. этого пропа просто нет в типе layoutProps здесь.
     * Конструкция с двумя присваиваниями ниже позволяет быть уверенным, что все пропсы относящиеся к GridLayout
     * извлечены корректно и не извлечено ничего лишнего. Если в GridLayoutProps добавятся новые пропсы которые тут не
     * перечислены, то тут будет ошибка ts.
     */
    const {
        leftSidebar,
        rightSidebar,
        hideLeftOnXL,
        hideRightOnXL,
        leftSidebarDataQA,
        rightSidebarDataQA,
        contentDataQA,
    } = layoutProps;
    const props: RequiredGridLayoutProps = {
        leftSidebar,
        rightSidebar,
        hideRightOnXL,
        hideLeftOnXL,
        leftSidebarDataQA,
        rightSidebarDataQA,
        contentDataQA,
    };

    return (
        <GridLayout {...props}>
            <RowContent isShrinked={isShrinked}>{children}</RowContent>
        </GridLayout>
    );
};

export default DefaultLayout;
