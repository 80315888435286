import { Link } from '@hh.ru/redux-spa-middleware';
import BlokoLink from 'bloko/blocks/link';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { formatToReactComponent } from 'bloko/common/trl';

import translation from 'lux/components/translation';

export enum SendDoubleOptInResponse {
    EmailVerificationWasSendAgain = 'email_verification_was_sent_again',
    EmailVerificationWasSendAgainTooOften = 'email_verification_was_sent_again_too_often',
    EmailVerificationWasSendAgainError = 'email_verification_was_sent_again_error',
}

const TrlKeys: Record<SendDoubleOptInResponse, string> = {
    [SendDoubleOptInResponse.EmailVerificationWasSendAgain]: 'notifications.email_verification_was_sent_again',
    [SendDoubleOptInResponse.EmailVerificationWasSendAgainTooOften]:
        'notifications.email_verification_was_sent_again_too_often',
    [SendDoubleOptInResponse.EmailVerificationWasSendAgainError]:
        'notifications.email_verification_was_sent_again_error',
};

interface EmailVerificationSendErrorProps {
    notification?: SendDoubleOptInResponse;
}

interface EmailVerificationSendProps {
    email: string;
    mailerUrl: string;
}

const EmailVerificationSendError: TranslatedComponent<EmailVerificationSendErrorProps> = ({ trls, notification }) => (
    <>{trls[TrlKeys[notification || SendDoubleOptInResponse.EmailVerificationWasSendAgainError]]}</>
);

export const emailVerificationSendError = {
    Element: translation(EmailVerificationSendError),
    kind: 'error',
    autoClose: true,
};

const EmailVerificationSend: TranslatedComponent<EmailVerificationSendProps> = ({ trls, email, mailerUrl }) => (
    <>
        {formatToReactComponent(trls[TrlKeys[SendDoubleOptInResponse.EmailVerificationWasSendAgain]], {
            '{0}': (
                <BlokoLink disableVisited Element={Link} to={`${mailerUrl}`}>
                    {email}
                </BlokoLink>
            ),
        })}
    </>
);

export const emailVerificationSend = {
    Element: translation(EmailVerificationSend),
    kind: 'ok',
    autoClose: true,
};
