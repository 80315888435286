import { Button, ButtonMode, ButtonStyle } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { LoadingActivityName } from 'lux/components/SavedSearch/types';
import translation from 'lux/components/translation';

const TrlKeys = {
    [LoadingActivityName.Subscribe]: 'savedSearches.subscribe.many',
    [LoadingActivityName.Unsubscribe]: 'savedSearches.unsubscribe',
    [LoadingActivityName.Delete]: 'savedSearches.delete',
};

interface ActivityButtonProps {
    activityName: LoadingActivityName;
    mode: ButtonMode;
    style?: ButtonStyle;
    currentLoadingActivityName?: LoadingActivityName;
    selectedItems: number[];
    handleChange: (loadingActivityName: LoadingActivityName) => void;
}

const ActivityButton: TranslatedComponent<ActivityButtonProps> = ({
    activityName,
    mode,
    style,
    currentLoadingActivityName,
    selectedItems,
    handleChange,
    trls,
}) => {
    const isDisabled = selectedItems.length === 0 || !!currentLoadingActivityName;

    return (
        <Button
            onClick={() => handleChange(activityName)}
            loading={currentLoadingActivityName === activityName}
            style={style}
            mode={mode}
            type="button"
            disabled={isDisabled}
            data-qa={`autosearch-header__${activityName}`}
        >
            {trls[TrlKeys[activityName]]}
        </Button>
    );
};

export default translation(ActivityButton);
